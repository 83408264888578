.getstarted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../images/homepage/Mask Group 16.svg");
  height: 31rem;
  width: 100%;
  background-size: cover;
}
.getquotewhitebutton {
  border-radius: 50px;
  padding: 12px;
  border: none;
  width: 160px;
  font-size: 20px;
  background-color: white;
  color: #2a7ad5;
  margin: 0;
}
