.landingWrapper {
  font-family: Switzer;
  letter-spacing: 0px;
  color: #2e2947;
  opacity: 1;
  font-size: 1.2rem;
}

.landingWrapper * {
  font-family: Switzer;
}
.sectionContainer {
  padding: 0 15px;
  width: 100%;
}

.sectionContainer h2 {
  font-size: 3.5rem;
  line-height: 5.5rem;
  font-weight: 400;
}

@media screen and (max-width: 576px) {
  .sectionContainer h2 {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1200px) {
  .sectionContainer {
    padding: 0 40px;
  }
}

@media screen and (min-width: 1400px) {
  .sectionContainer {
    padding: 0 140px;
  }
}
