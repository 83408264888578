.assignment{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 3rem;
    margin-left: 15%;
    margin-right: 15%;
}

.heading{
    display: flex;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    justify-content: left;
}
p{
    font-size: 1rem;
}

li{
    list-style: auto;
    margin: 4px 0;
}
ul{
    margin-left: 3rem;
}
h3{
    margin-top: 4rem;
}
hr{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid rgba(0,0,0,.1);

}