.videoContainer {
  margin-top: 10rem;
  margin-bottom: -6rem;
}
.iframeWrapper {
  max-width: 1200px;
}
@media screen and (max-width: 576px) {
  .videoContainer {
    margin-top: 10rem;
  }
}
@media screen and (max-width: 1000px) {
  .iframeWrapper{
    margin:0px;
  }
}
