.active {
  font-weight: 800;
}

.navBar {
  flex-flow: row wrap;
  background-color: transparent ;
  margin-top: 5px;
  margin-bottom: 40px;
  .navbarBrand {
    padding-bottom: 0%;
    padding-left: 3.8%;
    @media screen and (max-width: 1400px) {
      padding-left: 1%;
    }
    @media screen and (max-width: 1270px) {
      padding-left: 0%;
    }
    @media screen and (max-width: 1400px) {
      div {
        height: 66px !important;
        width: 400px !important;
      }
    }
    @media screen and (max-width: 1170px) {
      div {
        height: 46px !important;
        width: 196px !important;
      }
    }
  }
  .navbarCollapse {
    flex-grow: 0;
    .nav {
      
      .navLink {
        margin-top: -1.5%;
        
        &:first-child {
          margin-left: -7%;
          padding-left: 15px;
          @media screen and (max-width: 1800px) {
            margin-left: 0%;
            padding-left: 10px;
          }
        }
        font-size: 1.125rem;
        @media screen and (max-width: 1650px) {
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
        @media screen and (max-width: 1550px) {
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          font-size: 1rem;
        }
        @media screen and (max-width: 1170px) {
          padding-left: 0.4rem;
          padding-right: 0.4rem;
          width:50px;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 979px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.navBarScroll {
  background-image: linear-gradient(to right, rgb(255, 255, 255), rgb(255,255,255));
  opacity: 1;
  @media screen and (max-width: 1400px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  margin-top: 5px;
  margin-bottom: 40px;
  .navbarBrand {
    padding-bottom: 0%;
    padding-left: 4%;
    @media screen and (max-width: 1400px) {
      padding-left: 1%;
    }
    @media screen and (max-width: 1270px) {
      padding-left: 0%;
    }
    @media screen and (max-width: 1400px) {
      div {
        height: 66px !important;
        width: 268px !important;
      }
    }
    @media screen and (max-width: 979px) {
      div {
        height: 56px !important;
        width: 227px !important;
      }
    }
  }
  .navbarCollapse {
    flex-grow: 0;
    .nav {
      .navLink {
        margin-top: -1.5%;
        &:first-child {
          margin-left: -7%;
          padding-left: 15px;
          @media screen and (max-width: 1800px) {
            margin-left: 0%;
            padding-left: 10px;
          }
        }
        font-size: 1.125rem;
        @media screen and (max-width: 1650px) {
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
        @media screen and (max-width: 1550px) {
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          font-size: 1rem;
        }
        @media screen and (max-width: 1170px) {
          padding-left: 0.38rem;
          padding-right: 0.38rem;
          font-size: 0.96rem;

        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
}


@media screen and (max-width: 1800px) {
  .navLink{
    text-align: center;
  }
  
}


@media screen and (max-width: 1000px) {
  .navLink{
    text-align: left;
  }
  
}
.brandlogo{
  @media screen and (max-width: 1000px) {
    height:60px;
    width:150px;
}
}