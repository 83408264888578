.footer {
  padding: 64px 128px 0px;
}
ul {
  padding: 0;
}
li {
  list-style: none;
  margin: 24px 0;
}
.cc {
  margin: 48px 0;
}
.tc {
  width: 300px;
}
.heading {
  color: #c5c5c5;
  font-size: 16px;
}

.finelines {
  color: #6d7088;
  font-size: 15px;
}
.footercontainer {
  display: flex;
  justify-content: space-between;
}
.napeoContainer img {
  width: 100%;
}
@media screen and (max-width: 950px) {
  .footercontainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .litext li {
    font-size: 15px;
  }
}

@media screen and (max-width: 676px) {
  .footer {
    padding: 64px 15px 0px;
  }
  li {
    list-style: none;
    margin: 12px 0;
  }
}
