.profile {
  vertical-align: middle;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.details {
}
.profilecontainer {
  margin: auto;
  padding-top: 10px;
}
.arrow {
  width: 40px;
}
.testimonialtext {
  font-style: italic;
  font-size: 20px;
  /* height: 300px; */
  width: 80%;
}

/* Extra Small devices */
@media only screen and (min-width: 480px) {
  .testimonialtext {
    height: 300px;
    width: 80%;
  }
}

/* Small devices */
@media only screen and (min-width: 600px) {
  .testimonialtext {
    height: 200px;
    width: 80%;
  }
}

/* Medium devices */
@media only screen and (min-width: 768px) {
  .testimonialtext {
    height: 260px;
    width: 50%;
  }
}

/* Large devices */
@media only screen and (min-width: 992px) {
  .testimonialtext {
    height: 200px;
    width: 50%;
  }
}

/* laptops and desktops */
@media only screen and (min-width: 1200px) {
  .testimonialtext {
    height: 150px;
    width: 50%;
  }
}
