.processMain {
  background-color: #f5faff;
  padding-top: 200px;
  padding-bottom: 100px;
}

.imageBox {
  height: 80px;
  margin-bottom: 35px;
}

.processBox {
  background-color: #ffffff;
  padding: 80px 60px;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .processBox {
    padding: 40px 30px;
  }
}

.smalltext{
  color:#6D7088;
}