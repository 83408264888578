.heroRow {
  margin-top: -120px;
  background: linear-gradient(to right, white 50%, #f5faff 50%);
}
.getquote {
  border-radius: 50px;
  padding: 12px;
  border: none;
  width: 160px;
  background-color: #2a7ad5;
  color: white;
  margin: auto;
  font-size: 20px;
  font-weight: 500;
}

.getquote:hover {
  background-color: #1057a7;
}

.textColumn {
  padding-top: 120px;
  padding-bottom: 90px;
}

.imageColumn {
  max-height: 90vh;
  padding-top: 120px;
  padding-left: 185px;
  padding-bottom: 90px;
  background-color: #f5faff;
}

.background {
  height: 100%;
  z-index: -10;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: right center;
}

.introtext {
  padding-top: 4rem;
}

.introtext h1 {
  font-size: 4.375rem;
  line-height: 5.5rem;
}

@media screen and (max-width: 576px) {
  .heroRow {
    background: white;
  }
  .background {
    text-align: center;
  }
  .background img {
    width: 80%;
    object-position: center center;
  }
  .textColumn {
    padding-top: 0px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 992px) {
  .introtext h1 {
    line-height: 1;
    font-size: 3.5rem;
  }
  .imageColumn {
    padding-left: 0;
    padding-bottom: 30px;
  }
}
