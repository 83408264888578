.leaflet-container {
  height: 400px;
  width: 800px;
}

._loading_overlay_wrapper #navbarMain {
  margin-bottom: 20px;
}

#test {
  background-color: antiquewhite;
  display: block !important;
  width: 20px;
  height: 20px;
}

.heading {
  margin-top: 10px;
  font-weight: 600;

  color: black;
}
.big {
  color: black;
  font-weight: 600;
  font-size: 180%;
}

.heatmap {
  /* display: flex; */
  /* justify-content: space-between; */
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.leaflet-container {
  width: 100%;
}

.row1 {
  display: flex;
  justify-content: space-around;
}

.card-dashboard {
  background: lightgray;
  height: 220px;
  width: 420px;
  margin: 20px;
  text-align: center;
}

.card3 {
  margin-right: 0px !important;
}

.card1 {
  margin-left: 0px !important;
}

.premium {
  font-size: 20px;
  font-weight: 600;
  margin-top: 60px;
}

.tiny {
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px;
}
.total {
  font-size: 11px;
  font-weight: 500;
  color: gray;
}

.dtext {
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.smalltext {
  font-size: 14px;
}

.heading {
  font-weight: 600;

  /* Quoted Premium: $5,554,245

    Written Premium: $3,524,589 */

  font-size: 100%;
}

.row-card {
  /* padding: 10px; */
  /* background: powderblue; */
  display: flex;
  justify-content: space-between;
}

.card-test {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-with-data {
  color: navy;
  font-weight: 600;
}

.row-p2 {
  margin: 10px;
  margin-top: 0px !important;
  padding: 10px;
  background: lightgray;
  height: 95%;
  width: auto;
}

.row-p3 {
  padding: 10px;
  margin: 10px;
  margin-bottom: 0px !important;
  background: lightgray;
  height: auto;
  width: auto;
  position: fixed;
}

/* .col-p2 {
    background: red;
    height: 100%;
    width: 100%;
    margin: 10px;
}

.col-p3 {
    background: blue;
    height: 100%;
    width: 100%;
    margin: 10px;
} */
.tot-business {
  height: 100%;
  padding-top: 90px;
  background: lightgray;
  /* margin-top:50px; */
  /* margin-bottom: 50px; */
}
.abc1 {
  width: 100000px;
  margin-right: 10px;
  padding: 20px 10px 10px 10px;
  background-color: lightgray;
}

.abc2 {
  padding: 20px 10px 10px 10px;
  width: 100000px;
  margin-right: 10px;
  margin-left: 20px;
  background-color: lightgray;
}
.abc3 {
  padding: 20px 10px 10px 10px;
  width: 100000px;
  margin-right: 10px;
  margin-left: 20px;
  background-color: lightgray;
}
.pie1 {
  padding-top: 30px;
}
.pie2 {
  padding-top: 60px;
}
.pie3 {
  padding-top: 45px;
}
@media only screen and (max-width: 1200px) {
  .abc1 {
    width: 230px;
    padding: 10px 10px 5px 10px;
    background-color: lightgray;
  }

  .abc2 {
    padding: 10px 10px 10px 10px;
    width: 230px;

    background-color: lightgray;
  }
  .abc3 {
    padding: 10px 10px 10px 10px;
    width: 230px;

    background-color: lightgray;
  }
  .pie1 {
    padding-top: 0px;
  }
  .pie2 {
    padding-top: 70px;
  }
  .pie3 {
    padding-top: 37px;
  }
}

.contain {
  margin: 10px 10px 10px 10px;
  /* background-color: lightgray; */
}
.row4 {
  padding-top: 20px;
}
.boxes {
  /* flex-direction: row; */
  text-align: center;
  margin-left: 25px;
  /* display: inline-block; */
  float: left;
}
.box1 {
  height: 90px;
  width: 110px;
  background-color: darkblue;
  padding-top: 17px;
  color: white;
  font-weight: 900;
  font-size: small;
}
.box2 {
  height: 90px;
  width: 110px;
  padding-top: 17px;
  margin-top: 5px;
  background-color: gold;
  color: black;
  font-weight: 900;
  font-size: small;
}
.box3 {
  height: 90px;
  width: 110px;
  padding-top: 17px;
  margin-top: 5px;
  background-color: darkgreen;
  color: white;
  font-weight: 900;
  font-size: small;
}
.show-data {
  padding-top: 5px;
  font-size: small;
  font-weight: 900;
}
.chart1 {
  /* display: inline-block; */
  height: 300px;
  /* width: 100px; */
  /* padding-top: 5px; */
  /* padding-bottom: 10px; */
  margin-left: 20px;
  /* background-color: pink; */
  float: left;
}
.chart1 .donut-container {
  position: relative;
}
.chart1 .donut-container #donut-middle-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  width: fit-content;
  height: fit-content;
  margin-bottom: auto;
}
.chart2 {
  /* display: inline-block; */
  height: 300px;
  width: 360px;
  /* margin-top: 0px; */
  /* padding-top: 25px;s */
  /* padding-left: 0px; */
  /* background-color: red; */
  float: left;
}
.chart3 {
  /* display: inline-block; */
  height: 300px;
  /* width: 100px; */
  /* margin-bottom: 10px; */
  /* padding-top: 5px; */
  margin-left: 10px;
  /* background-color:blue; */
  float: left;
}
.block_container {
  margin-top: 85px;
  display: flex;
}
.table-header {
  font-size: 10.5px;
  background-color: lightgray;
}
.rt-align {
  text-align: right;
}
.lt-align {
  text-align: left;
}

.left-element {
  margin-bottom: 20px;
  display: inline-block;
  float: left;
}

.right-element {
  padding-left: 15px;
  margin-bottom: 20px;
  display: inline-block;
  float: left;
}
.tables {
  margin-left: 25px;
  margin-right: 20px;
  font-size: 9px;
  display: flex;
}
.dropdow {
  justify-content: flex-end;
  display: flex;
}
.dropdow > div > span:first-child {
  font-size: 11px;
  font-weight: bolder;
}
.drop3 {
  width: 15rem;
  height: 3rem;
  margin-right: 0.75rem;
  font-size: 12px;
}
.drop1 {
  width: 15rem;
  height: 3rem;
  margin-right: 0.75rem;
  font-size: 12px;
}
.drop2 {
  font-size: 12px;
  height: 3rem;
  width: 15rem;
}
.drop2 button {
  height: 3rem;
  width: 15rem;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 4px;
  padding: 0 0 0 12px;
  font-size: inherit;
}
.drop2 .react-select-checkbox__control {
  min-width: 15rem;
  width: 15rem;
  margin: 0;
  min-height: unset;
  height: 3rem;
  height: 2.5rem;
}
.drop2 .react-select-checkbox__menu {
  width: 15rem;
}
.drop2 .react-select-checkbox__menu-list {
  font-size: 11px;
  padding-bottom: 0;
  max-height: 90px;
}
.drop2 .react-select-checkbox__menu-list > div {
  padding: 0 0 0 4px;
  min-width: 13.5rem;
  width: fit-content;
}
.drop2 .react-select-checkbox__menu-list > div:first-child {
  border-bottom: 1px solid black;
  font-weight: bolder;
}
.drop2 .react-select-checkbox__menu-list > div:last-child {
  padding-bottom: 2px;
}
.drop2 .react-select-checkbox__value-container {
  padding: 0 0 0 4px;
}
.drop-policy{
  /* background-color: white; */
  height: 1.9rem;
  width: 5rem;
  font-size: small;
}

.aaa {
  width: 120px;
  height: 30px;
  float: right;
  margin-right: 10px;
}

.row4 {
  margin-right: 0px;
  margin-left: 25px;
}

/* for legend */
.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  /* margin-right: 8px; */
  opacity: 0.7;
}
.legend label {
  margin-right: 13px;
  /* margin-left: 10px; */
}
.legend x {
  font-weight: 900;
}
.css-1gpjby2{
  width: 220px;
}
.css-1v99tuv{
  display: contents !important;
}