//design for the new quote component

input[type="number"] {
  -moz-appearance: textfield;
}

input {
  padding: 0.5rem;
}

.per5 {
  width: 5%;
  max-width: 5%;
}

.per20 {
  width: 20%;
  max-width: 20%;
}

.per10 {
  max-width: 10%;
  width: 10%;
}

body {
  -webkit-font-smoothing: antialiased;
}

class-desc-select .class-desc-select__menu {
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.contentHolder-history {
  min-height: calc(100vh - 15.75rem);
}

@mixin cmprQts {
  //style of the background image:
  background-image: url("../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;

  .quote-table {
    #loss-parse-error {
      color: #dc3545;
    }
    .quote-table-company-name {
      padding-right: calc(2rem + 25px);
    }
    .head-row {
      align-items: center;
      justify-content: space-between;
    }
    .hr1 {
      color: #2c2e33;
      font-size: 26px;
      font-weight: 500;
      &.quote-table-company-name {
        font-family: "Overpass", sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .hr2 {
      color: #2c2e33;
      width: 70%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      span {
        color: #5f636b;
      }
    }
    .hr3 {
      margin-bottom: 1.5rem;
      color: #2c2e33;
    }
    .body-row {
      padding-left: 6px;
      margin-top: 2rem;
      // justify-content: left;
      .table {
        margin-bottom: 7rem;
        position: relative;
        right: 2rem;
        border-collapse: separate;
        border-spacing: 25px 0px;
        thead {
          th {
            color: #2c2e33;
            position: relative;
            right: 0.6rem;
            font-size: 16px;
          }
        }
        tbody {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;
          td,
          th {
            width: 25%;
            border: 1px solid white;
            background-color: white;
            color: #2c2e33;
            font-size: 18px;
            font-weight: normal;
            div {
              height: 100%;
              margin-left: 6px;
              label {
                margin-bottom: 0;
              }
            }
          }
          td {
            label {
              font-size: 22px;
              font-weight: 800;
              color: #1463ac;
              margin-bottom: 0;
            }
            .best {
              font-size: 14px;
              margin-left: 1rem;
              color: #038b00;
              white-space: nowrap;
            }
            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
              white-space: nowrap;
            }
            .uw {
              font-size: 14px;
              margin-left: 1rem;
              white-space: nowrap;
            }
            input {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.new-navbar {
  margin-bottom: 1rem;
  height: 4.75rem;
  background-color: #1463ac;
  color: white;
  font-weight: 600;
  font-size: 16px;
  .forBig {
    margin-left: auto;
  }

  .navbar-brand {
    margin-top: auto;
    margin-bottom: auto;
  }

  .new-logo {
    height: 3.375rem;
    margin-left: 2.2rem;
  }

  .boxed-logo {
    background-color: #fff;
    padding: 20px;
    height: 125px;
    margin: 5px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 20px;
    left: 20px;
  }

  button {
    border: none;
    color: white;
    background: none;
    font-weight: 600;
  }

  span {
    color: white;
    font-weight: 600;
  }

  ul {
    li {
      list-style: none;
      // padding-bottom: 10px;
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}

.secDesc {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

#small-loader {
  width: fit-content;
  height: fit-content;
  /* margin-top: 1rem; */
  > img {
    width: 2rem;
  }
}

#rate1,
#rate2 {
  margin-bottom: 5px;
}

#compareQuote {
  .btnSubmits {
    margin-top: 2rem;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    // margin-top: 30px;
    // margin-bottom: 36px;
  }
  .btnView {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #1463ac;
    margin-right: 1rem;
    margin-top: 2rem;
    font-size: 14px;
    font-weight: 800;
  }
}

.errMsg {
  color: red;
}

.newquote-mainbody {
  //global styles for quote main body
  background-color: #e6edf0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  .forSmall {
    display: none;
  }

  .newquote-content {
    //global styling for everything in the light blue background

    padding-left: 1.5rem;
    padding-right: 1.6rem;
    width: 90%;
    margin-top: 1rem;
    padding-bottom: 2rem;
    background-color: #e2f2ff;

    .emodDate,
    form select,
    form input[type="text"],
    form input[type="email"],
    form input[type="password"],
    form input[type="date"],
    form textarea {
      width: 100%;
      font-size: 0.85em;
      border-radius: 4px;
      border: 1px solid white;
      background: white;
      color: #2b2d31;
      font-weight: "normal";
      &:hover {
        // border: 0px;
      }
    }

    input[type="checkbox"] {
      height: 16px !important;
      width: 16px;
      border-radius: 4px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }

    //styling of the headers and section-headers:
    // this is styling for all the different stages
    // for changing any heading, please refer to this section only
    .formTitle {
      color: #2c2e33;
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.38;
      &.location-details-heading {
        font-weight: 400;
        font-size: 1.2rem;
      }
      &.compName {
        font-family: Overpass;
        font-size: 20px;
        font-weight: 600;
        color: #2c2e33;
        // padding-left: 4rem;
      }
      &.title2 {
        width: 70%;
        display: inline-block;
      }
      &.title3 {
        font-size: 20px;
        font-weight: normal;
      }
    }
    .addressRate,
    .aggregate-heading,
    .stateAggName {
      font-family: Overpass;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2d31;
    }
    //end of heading/subheading styles

    //styling of buttons:
    .transpButton {
      background-color: transparent;
      border-color: transparent;
    }

    .btnView {
      width: 210px;
      height: 40px;
      border-radius: 5px;
      background-color: #1463ac;
      margin-right: 1rem;
      margin-top: 2rem;
      font-size: 14px;
      font-weight: 800;
    }

    .btnSubmits {
      width: 210px;
      height: 40px;
      border-radius: 5px;
      background-image: linear-gradient(to right, #10c03e, #0eaf84);
      box-shadow: none;
      border-color: transparent;
      color: white;
      font-size: 14px;
      font-weight: 800;
    }
    // end of button styling

    //style for the progress bar at the top of every page
    // please refer to this section only for styling the progress bar
    .color-bar {
      margin-top: 1.5rem;
      margin-bottom: 44px;
      // margin-left: 1.2rem;
      width: 100%;
      height: 12px;
      border-radius: 8px;
      background-image: linear-gradient(to right, #7d8fec, #2fe6db);
      span {
        display: block;
        height: 100%;
        background-color: #0dd209;
        border-radius: 8px;
      }
    }
    // end of progress bar styles
    //style for company profile tab
    #paymentQuote {
      span.errMsg {
        color: red;
        font-size: 11px;
      }
    }
    #compProfile {
      background-image: url("../images/backimage1.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;
      .formTitle {
        margin-bottom: 34px;
      }
      .company-locations {
        margin-bottom: 30px;
      }
      .mandate {
        color: #919399;
      }
      small {
        color: #333333;
      }
      .form-group {
        margin-bottom: 38px;
        select,
        input {
          height: 35px;
          color: #2b2d31;
          font-size: 14px;
          padding-left: 5px;
        }
        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 11px;
          color: #333;
          margin-bottom: 0;
          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }
        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }
      textarea {
        padding-left: 5px;
        height: 123px;
      }

      #allLocations {
        margin-top: 2rem;
      }
      .address-delete {
        margin-top: 1.2rem;
      }
      .linkLike {
        position: relative;
        bottom: 20px;
      }
      #otpSendMsg,
      #otpSentMsg,
      #otpVerify,
      #verifySuc {
        display: none;
        font-size: 0.8em;
      }
      #otpSec,
      #resendOTP {
        display: none;
      }
      #emailStatus,
      #verifyStatus {
        margin-top: 1rem;
      }
      #timerOTP {
        font-size: 0.8rem;
      }
      .btn-link {
        padding-left: 0;
      }
    }

    #cmprRate {
      //style block for compare rate page/tab
      //styling for the background image
      background-image: url("../images/backimage2.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 100%;
      background-attachment: sticky;

      // .download-link-div {
      //   width: 20px;
      // }

      .formTitle {
        margin-bottom: 60px;
      }

      .title3 {
        margin-bottom: 25px;
      }

      .code-delete {
        // margin-top: 0.25rem;
      }

      .linkLike {
        margin-bottom: 37px;
      }

      .calcPremium {
        color: #919399;
        input {
          height: 36px;
          color: #2b2d31;
          font-size: 14px;
          padding-right: 5px;
        }
      }

      .rangeSlider {
        padding: 1.2rem;
        .row {
          // justify-content: space-around;
          margin: 0;
          width: 100%;
        }
        .premVal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35%;
          height: 35px;
          border-radius: 20px;
          background-color: #2987de;
          font-size: 20px;
          font-weight: 800;
          color: #ffffff;
          span {
            position: relative;
            top: 2px;
          }
        }
        .barBG {
          margin-left: 1rem;
          margin-right: 1rem;
          margin-top: 1rem;
          width: 12px;
          height: 2px;
          background-color: #2c2e33;
        }
      }

      #attachSec {
        margin-top: 47px;
        .no-lossrun-affidavit-heading {
          color: #2b2d31;
          font-weight: "normal" !important;
          font-size: 14px;
        }
        .dropped-file {
          color: #4169ba;
        }
        .drop-zone {
          margin-top: 20px;
          margin-bottom: 44px;
          display: flex;
          background: white;
          opacity: 0.5;
          justify-content: center;
          align-items: center;
          height: 106px;
          width: 385px;
          border: 1px dashed black;
          span {
            color: #1463ac;
          }
        }
        .drop-info {
          margin-top: 20px;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      #claimTable {
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }
        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;
        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;
            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
      .btnSubmits {
        margin-top: 30px;
        margin-bottom: 36px;
      }
      .form-group {
        select,
        input {
          height: 35px;
          color: #2b2d31;
          font-size: 14px;
          padding-left: 5px;
        }
        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 11px;
          color: #333;
          margin-bottom: 0;
          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }
        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }
      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
      }
    }

    #undrQues {
      // style for the background image:
      background-image: url("../images/backimage3.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      //style for the unerwriter questions and additional questions table:
      .heading-row {
        justify-content: space-between;
        .compName {
          margin-top: 2px;
          color: #2c2e33;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .title2 {
        margin-bottom: 50px;
      }
      .table-addnques,
      .table-undrques {
        margin-bottom: 3.5rem;
        thead {
          color: #2c2e33;
          font-size: 1.2rem;
          tr {
            th {
              border: none;
              &.tableQues {
              }
              &.tableAns {
              }
              &.tableRem {
              }
            }
          }
        }
        tbody {
          tr {
            td {
              vertical-align: middle;
              padding-top: 5px;
              padding-bottom: 5px;
              &.tableAns {
                .radio-btn-no {
                  margin-left: 2.5rem;
                  width: 18px;
                  height: 18px;
                }
                .radio-btn-yes {
                  margin-left: 1rem;
                  width: 18px;
                  height: 18px;
                }
                .radiobtn {
                  margin-left: 4px;
                  position: relative;
                  bottom: 4px;
                }
              }
              &.tableQues {
                width: 60%;
              }
              &.tableRemarks {
                padding-right: 5px;
                input {
                  height: 32px;
                  border: 1px solid #aaa;
                }
                .hidden {
                  background-color: rgba(131, 165, 191, 0.2);
                  border: none;
                }
              }
              &.table-question {
                margin: 10px;
              }
            }

            &:nth-child(odd) {
              // margin-left: -20px;
              background-color: #ffffff;
              opacity: 0.7;
              .tableRemarks {
              }
            }
          }
        }
      }
    }
    .additional-questions {
      margin-bottom: 30px;
    }
    .table-undrques {
      margin-bottom: 2rem;
      tbody {
        .trow1 {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }
      }
    }
    .table-addnques {
      .tableAns {
        width: 18%;
      }
    }
    .btn-undrques {
      margin-bottom: 2rem;
    }

    #cmprQts {
      @include cmprQts;
    }

    //styling of review and pay page:
    #payBind {
      input,
      select {
        height: 40px;
      }
      .formTitle {
        margin-bottom: 37px;
      }

      #carrierName {
        font-size: 16px;
      }
      background-image: url("../images/backimage5.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;
      .row-formTitle {
        justify-content: space-between;
        .formTitle {
          margin-bottom: 0rem;
        }
      }
      .row-acord-preview-heading {
        justify-content: space-between;
        .col-acord-text {
          margin-left: 16px;
        }
        .col-acord-download {
          margin-left: 15px;
          padding-left: 36px;
        }
        #accordLink {
          color: #0973d5;
        }
        img {
          margin-right: 1rem;
        }
      }
      .row-acord-preview {
        margin-top: 2rem;
        justify-content: center;
        margin-bottom: 81px;
        iframe {
          border: none;
          width: 78%;
          height: 30rem;
        }
      }
      .pdf-upload-placeholder {
        width: 78%;
        height: 30rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 81px;
        .pdf-loader-gif {
          margin-left: 1.5rem;
        }
      }
      .row-tnc {
        margin-top: 1rem;
        font-size: 16px;
        color: #2c2e33;
        p {
          margin-top: 0.6rem;
        }
        .payPageItems2 {
          margin-left: 2rem;
        }
        .linkLike {
          margin-left: 0.5rem;
        }
      }
    }

    //styling for bind page
    #binderForm {
      .linkLike {
        margin-top: 60px;
        margin-bottom: 36px;
      }
      .btnGen {
        margin-bottom: 10px;
      }
      .col-sm-12 {
        .formTitle {
          margin-bottom: 1rem;
        }
        .row-binder-heading {
          padding-right: 10px;
          padding-left: 10px;
          justify-content: space-between;
        }
        .row-iframe {
          justify-content: center;
          width: 100%;
          .col-iframe {
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;
            .acord {
              justify-content: flex-end;
              width: 95%;
              color: #0973d5;
              span {
                position: relative;
                top: 4px;
                margin-left: 0.5rem;
              }
            }
            .row-iframe-preview {
              width: 100%;
              justify-content: center;
              iframe {
                // margin-top: 2rem;
                border: none;
                width: 90%;
                height: 45rem;
              }
            }
          }
        }
      }
    }

    .class-desc-select {
      background-color: white;
      div {
        height: 35px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .class-desc-select__indicator {
        align-items: center;
      }
      .class-desc-select__menu {
        height: fit-content;
        width: fit-content;
        max-width: 350px;
        min-width: -webkit-fill-available;
        min-width: -moz-available;
        .class-desc-select__menu-list {
          height: fit-content;
          max-height: 150px;
          .class-desc-select__option {
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: fit-content;
          }
        }
      }
      .class-desc-select__placeholder {
        margin: 0;
        position: unset;
        transform: none;
        height: fit-content;
      }
      .class-desc-select__control {
        // min-height: 35px;
        // border: 1px solid #ababab;
        // background: transparent;
        // border-radius: 4px;
        .class-desc-select__value-container {
          overflow: initial;
          .class-desc-select__single-value {
            height: fit-content;
          }
        }
      }
    }

    .locationName {
      span {
        font-size: 16px;
        font-weight: normal;
        color: #2b2d31;
      }
    }

    #extraInput {
      margin-top: 5px;
    }

    .check {
      margin-right: 0.3rem;
    }

    #newAddress {
      display: none;
      margin-left: 0;
    }

    .payPageItems {
      margin-left: 1rem;
    }

    .linkLike {
      position: relative;
      right: 0.55rem;
      // margin-top: 68px;
      // margin-bottom: 63px;
    }
    // // styl for binder
    .green {
      margin-left: 25%;
      font-size: 22px;
      font-weight: 600;
      color: #138f11;
      margin-bottom: 16px;
      p {
        margin: 0;
      }
    }
    .endForm {
      margin-left: 2rem;
    }
  }

  //styling for review and pay
  //paybind is the main container of review and pay page:

  // styles of side tab

  .newquote-sidebar {
    width: 23.5%;
    // max-width: 50%;
    padding-left: 0.2rem;
    background-color: #ffffff;
    margin-top: 1rem;
    padding-bottom: 2rem;
    margin-right: 1rem;
    font-family: "Overpass", sans-serif;
    .nav-item {
      outline: none;
      border-radius: none;
      .tick {
        margin-right: 0.08rem;
        margin-top: 1rem;
        float: right;
      }
      .nav-link {
        border: none;
        margin-bottom: 1rem;
        color: #2c2e33;
        font-size: 16px;
        font-weight: 800;
        margin-left: 0.5rem;
        &.active {
          color: #0068c9;
          border: none;
        }
        .sidebar-stagename {
          margin-left: 1.2rem;
        }
        &.sidebar-heading {
          margin-top: 30px;
          color: #6b6666;
        }
        .disabled {
          display: none;
        }
      }
    }
  }
}
#downloadable {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  font-size: 14px;
  strong {
    font-size: 16px;
  }
}

.dashboard {
  .legend-placeholder {
    position: absolute;
    bottom: 25px;
    right: 10px;
    width: 95px;
  }
}

#downloadable {
  .sectionHolder {
    padding: 20px;
    margin: 30px auto;
  }
}

.home-page {
  //styling of the new home page
  #banMain {
    img {
      width: 100%;
    }

    h2 {
      font-family: "Merriweather", sans-serif;
      color: #2162ac;
      font-size: 50px;
      font-weight: 600;
      margin-top: 26px;
      margin-bottom: 16px;
    }

    h3 {
      color: #2f3237;
      font-size: 20px;
      line-height: 1.5;
      font-weight: normal;
      margin-bottom: 36px;
    }
    .btn {
      color: white;
      font-family: "Rubik", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border-radius: 7.2px;
      color: #fff;
      background-color: #f0ad4e;
      border-color: #eea236;
      margin-bottom: 30px;
      box-shadow: 2px 4px 12px #eea236;
    }
  }
}

#itWorks {
  margin-right: 0px;
  margin-left: 0px;
  .row-3steps {
    // width: 100%;
    padding: 40px 0 40px 0;
    height: auto;
    background-color: #2162ac;
    // background-color: #2e3952;

    //3 steps logo
    .col-simplesteps {
      color: white;
      text-align: left;
      .simple-steps-wrapper {
        max-width: 300px;
      }
      h2 {
        text-align: left;
        font-size: 200px;
        margin-bottom: 0px;
        line-height: 1;
      }
      h3 {
        font-size: 44px;
        font-weight: 300;
        text-align: left;
      }
      img {
        margin: 1rem;
        position: relative;
        left: 15px;
        display: none;
      }
      .line-below-3 {
        width: 180px;
        height: 4px;
        opacity: 0.5;
        background-color: #ffffff;
        margin-bottom: 1rem;
        margin-top: 10px;
      }
    }

    //3-steps cards
    .col-simplesteps-cards {
      .card {
        width: 90%;
        color: white;
        background-color: transparent;
        .row {
          align-items: center;
          .col-card-image {
            height: auto;
            margin-right: 10px;
            .card-img {
              border: 0px !important;
              width: 97px;
              object-fit: contain;
              // img {
              //   margin-bottom: 35px;
              // }
            }
          }
          .col-card-text {
            height: auto;
            .card-body {
              text-align: left;
              .card-title {
                font-family: "Rubik", sans-serif;
                font-size: 28px;
                font-weight: 500;
              }
              .card-text {
                margin-top: auto;
                font-family: "Rubik", sans-serif;
                font-size: 20px;
                line-height: 1.5;
                font-weight: normal;
              }
              .learn-more {
                font-family: Arial, Helvetica, sans-serif;
                color: #2ed359;
                font-size: 16px;
                font-weight: bold;
              }
              .learn-more-arrow {
                // font-size: 2rem;
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }

  .row-our-mission {
    color: white;
    height: 420px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 153, 255, 0.4) 100%
    );
    background-image: url("../images/home-mission-background.jpg");
    .col-md-12 {
      padding: 80px 80px 80px 120px;
      h1 {
        position: relative;
        right: 13px;
        font-family: "Merriweather", sans-serif;
        font-size: 100px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      h2 {
        font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5;
      }
    }
  }

  .row-everything-you-need {
    .col-md-12 {
      padding: 115px;
      h1 {
        color: #353a3f;
        font-family: "Merriweather", sans-serif;
        font-weight: bold;
        font-size: 44px;
      }
      .card-row {
        .card {
          background-color: #f8f9fb;
          height: auto;
          margin: 20px;
          text-align: center;
          img {
            height: 80px;
            width: auto;
            margin-top: 40px;
            margin-bottom: 30px;
          }
          .card-title {
            color: #33333c;
            font-family: "Rubik", sans-serif;
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .row-video {
    background-color: #e7f4ff;
    height: auto;
    justify-content: center;
    .col-md-12 {
      padding-left: 114px;
      padding-right: 114px;
      padding-top: 100px;
      h1 {
        color: #2c2e34;
        font-family: "Merriweather", sans-serif;
        font-size: 44px;
        font-weight: bold;
        line-height: 1.27;
      }
      h2 {
        color: #2c2e34;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 40px;
      }
      iframe {
        margin-bottom: 120px;
      }
    }
  }
}

#footerMain {
  font-family: "Rubik", sans-serif;
  padding-top: 24px;
  padding-bottom: 20px;
  color: white;
  // background-color: #2e3952;
  background-color: #2162ac;
  padding-left: 100px;
  padding-right: 100px;
  .row-1 {
    div:nth-of-type(6) {
      margin-right: 10px;
    }
    justify-content: space-between;
    ul {
      padding-left: 2px;
      list-style: none;
      font-size: 16px;
    }
    .col-img {
      img {
        height: 67%;
        width: 32%;
      }
    }
    .col {
      a {
        color: white;
        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  .t-and-c-row {
    // opacity: 0.6;
    font-size: 13px;
    line-height: 2.23;
    .col-sm-2 {
      a {
        color: white;
      }
    }
    .dull {
      opacity: 0.6;
    }
    .back-to-top {
      margin-right: 10px;
    }
  }
}

.quote-table {
  .head-row {
    align-items: center;
    justify-content: space-between;
  }
  .hr1 {
    color: #2c2e33;
    font-size: 26px;
    font-weight: 500;
    &.quote-table-company-name {
      font-family: "Overpass", sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .hr2 {
    color: #2c2e33;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    span {
      color: #5f636b;
    }
  }
  .hr3 {
    margin-bottom: 1.5rem;
    color: #2c2e33;
  }
  .body-row {
    margin-top: 2rem;
    justify-content: center;
    .table {
      margin-bottom: 7rem;
      position: relative;
      right: 1rem;
      border-collapse: separate;
      border-spacing: 25px 0px;
      thead {
        th {
          color: #2c2e33;
          position: relative;
          right: 0.6rem;
          font-size: 16px;
        }
      }
      tbody {
        font-size: 18px;
        td,
        th {
          width: 25%;
          border: 1px solid white;
          background-color: white;
          color: #2c2e33;
          font-size: 18px;
          font-weight: normal;
        }
        td {
          p {
            font-size: 22px;
            font-weight: 800;
            color: #1463ac;
            .best {
              font-size: 14px;
              margin-left: 1em;
              color: #038b00;
            }
            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
          }
          input {
            margin: 0.5rem;
          }
        }
      }
    }
  }
}

#resDrop,
#workDrop,
#quotesDrop,
#profDrop {
  position: relative;
  display: none;
  padding: 0;
}
#resDrop .navDrop,
#quotesDrop .navDrop,
#workDrop .navDrop,
#profDrop .navDrop {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: left;
  width: 180px;
  top: 10px;
  border-radius: 6px;
  box-shadow: 5px 5px 20px #888888;
}
#quotesDrop .navDrop {
  right: 0px;
  padding: 10px;
}
#profDrop .navDrop {
  width: 200px;
  right: 0px;
}
#profDrop hr {
  margin-top: 0;
  margin-bottom: 0;
}
#profDrop .navDrop li {
  display: inline-block;
  padding: 15px;
  color: #095d98;
  font-size: 1.5rem;
  font-style: initial;
}
#resDrop .navDrop li,
#quotesDrop .navDrop li,
#workDrop .navDrop li {
  display: block;
  color: #095d98;
  padding: 10px;
}
#Dropresources:hover #resDrop,
#Dropworks:hover #workDrop,
#Dropquotes:hover #quotesDrop,
#Dropprofile:hover #profDrop {
  display: block;
}
#Dropprofile:hover #profDrop {
  display: block;
}

#resDrop .navDrop img,
#workDrop .navDrop img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.tab-content {
  // height: 600px;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  width: 100%;
}
// .tab-content-login,
// .tab-content-compliance {
//   min-height: calc(100vh - 15.75rem);
// }
// .tab-content-quote {
//   min-height: calc(100vh - 9rem);
// }
#loginButton {
  margin-left: 1rem;
  margin-right: 1rem;
}
#formLogin {
  height: auto;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}
#formChange {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset {
  // min-height: 390px;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset,
#formLogin,
#formChange {
  border: 1px solid #f9f9f9;
}

#formReset:hover,
#formLogin:hover,
#formChange:hover {
  box-shadow: 0 10px 15px #888888;
  transition: all 0.5s;
}
#lgin {
  /* padding: 150px 0; */
  transition: all 0.5s;
  margin: auto;
}
#chngpwd,
#fgtpwd {
  margin: auto;
}

.forSmall {
  display: none;
}

#loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  text-align: center;
  display: none;
}
#loader img {
  position: relative;
  width: 80px;
  top: 40%;
  margin: auto;
}

#formReset,
#formChange,
#formLogin {
  // margin-top: -16.5px;
  margin-bottom: 16px;
  .formTitle {
    font-size: 20px;
  }
  background-color: #e2f2ff;
  form {
    // margin-top: 10px;
    padding: 0 20px;
  }
  form input[type="password"],
  form input[type="email"],
  form input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  button {
    height: 35px;
    width: 100%;
    border-radius: 5px;
    background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .formBottomLinks {
    // padding: 20px 20px 50px 20px;
    justify-content: space-between;
    a {
      color: #2c2e33;
    }
  }
  .inptSideText {
    color: #5f636b;
  }
}
.formBottomLinks {
  padding: 20px;
}

#changePassForm {
  input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#quoteMain {
  @include cmprQts;
  background-color: #e2f2ff;
  #contentHolder {
    table {
      position: relative;
      right: 32px;
    }
  }
}

#resDrop,
#workDrop {
  .navDrop {
    padding: 0px;
    margin-left: -4rem;
    margin-top: 3rem;

    li {
      color: #0068c9;
      font-size: 13px;
      img {
        margin-left: 0px;
        margin-right: 8px;
        padding-left: 0px;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
}

.cmprQts-buttons {
  margin-bottom: 10px;
  text-align: center !important;
  // margin-left: 14rem;
}

.btnGen {
  margin-bottom: 36px;
}

#uwPortal {
  .fixedHeader {
    thead > tr > th {
      position: sticky;
      top: -1px;
      z-index: 3;
      background-color: white;
    }
    tbody > tr > td {
      vertical-align: middle;
    }
  }
  display: flex;
  height: calc(100vh - 5.7rem);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  .btn-sm {
    min-width: 60px;
    margin-bottom: 5px;
  }
  .custom-control-label::before {
    color: #fff;
    background-color: #e97d88;
    border-color: #e97d88;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }
  .custom-switch .custom-control-label::after {
    background-color: #fff;
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  }
}

.modal-full {
  width: 95%;
  max-width: none !important;
  height: 95%;
  iframe {
    min-height: 80vh;
  }
}

.class-desc-select__menu {
  width: 60vw !important;
  max-width: none !important;
}
.class-desc-select__option {
  width: auto !important;
}

.demo-container {
  font-size: 1rem;
  font-family: Switzer;
  #lgin {
    margin: 0;
  }
  .grid-banner-container {
    width: 75%;
    margin-bottom: 50px;
    .grid-banner {
      .grid-banner-header {
        font-size: 3.5rem;
        margin-bottom: 27px;
      }
      .grid-banner-logo-container {
        .insurecomp-logo {
          width: 33%;
          height: 100%;
          margin-right: 25px;
        }
        .affinity-logo {
          width: 30%;
          height: auto;
          margin-left: 25px;
        }
      }
    }
  }
  .table-container {
    width: 75%;
    table {
      td {
        height: 206px;
        width: 25%;
        .cell-header {
          font-size: 1.2rem;
          .header-link {
            cursor: pointer;
          }
          .header-link:hover {
            text-decoration: underline;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none !important;
            span {
              cursor: pointer;
              color: #007bff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .demo-login-container {
    .tab-content-login {
      min-height: auto;
    }
  }
  .banner-container {
    margin-bottom: 45px;
    margin-top: 90px;
    .demo-banner {
      .banner-text {
        width: 70%;
        font-weight: bold;
        font-size: 3.5rem;
      }
      .banner-video {
        margin-bottom: 100px;
      }
    }
  }
  .form-section-container {
    width: 75%;
    margin-bottom: 115px;
    .demo-input-form-section {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .btn {
        color: white;
        font-weight: normal;
        border-radius: 7.2px;
        color: #fff;
        background-color: #f0ad4e;
        border-color: #eea236;
        margin-top: 50px;
        box-shadow: 2px 4px 12px #eea236;
      }
      .input-form-header {
        margin-bottom: 50px;
        font-size: 2rem;
        font-weight: bold;
        color: rgb(52, 103, 174);
      }
      .form-container {
        .form-group:has(input[required]) label::after {
          content: "*";
        }
        .form-success {
          font-size: 2.5rem;
          padding: 60px 10px;
          background-color: rgb(92, 92, 92);
          color: white;
        }
      }
    }
  }
}
.testing-main-container {
  height: 100vh;
  margin: 0 auto;
}
.ManualRateDownloadButton {
  height: 45px;
  width: 97px;
}
@media only screen and (max-width: 500px) {
  #formLogin {
    width: 300px;
  }
}
